import React, { useState } from 'react'
import Filter from '../../../context/Filter'
import Fetch from '../../../components/Fetch'
import Columns, { ColumnsContext } from '../../../context/Columns'
import List from '../../../context/List'
import T, { ColumnsSelector, Pager, ExportTable } from '../../../components/Table'
import Filters from './_Filters'

import {
  FiRefreshCcw,
  FiFilter
} from 'react-icons/fi'

export default function Table( props ) {
  const [ query, setQuery ] = useState( {} )
  const [ reload, setReload ] = useState( 0 )
  const [ isFiltersOpened, setIsFiltersOpened ] = useState( false )
  const { schema } = props
  const selected = useState( [] )

  function refresh( event ) {
    setReload( reload + 1 )
  }

  function toggleFilters() {
    setIsFiltersOpened( ! isFiltersOpened )
  }

  if ( ! schema )
    return

  const before = null
  /*
  const before = schema.name === 'pcatalog' ? {
    __external: {
      from: 'datos',
      localField: 'obra_interno',
      foreignField: 'IdObraInternoCEDRO',
      match: { Estado: { $ne: 'Pendiente' } },
      unique: true
    }
  } : null
    */

  return (
    <Columns
      endpoint={ `/api/collection/${ schema.name }/columns` }
    >
      <Filter>
        <div className="title">
          <h1>{ schema.title }</h1>

          <div className="actions">

            <ExportTable
              action={ {
                title: 'exportar tabla',
                icon: 'HiOutlineDocumentReport',
                endpoint: `/api/collection/${ schema.name }/export`,
                useFilter: 1,
                prefix: `${ schema.name }-`
              } }
            />

            <button title="filtrar" onClick={ toggleFilters } >
              <FiFilter />
            </button>

            <button title="actualizar" onClick={ refresh }>
              <FiRefreshCcw />
            </button>

            <ColumnsSelector
              action={ {
                title: 'seleccionar columnas',
                icon: 'FiColumns'
              } }
            />
          </div>
        </div>

        <ColumnsContext.Consumer>
          { context => ( <>
            <div className="hs one">
              <List>
                <Fetch
                  type="table"
                  endpoint={ `/api/collection/${ schema.name }/list` }
                  query={ query }
                  reload={ reload }
                  useFilter={ true }
                  before={ before }
                >
                  { ( data, update ) => (
                    <div className="hs card table">
                      <T
                        data={ data.page }
                        columns={ context.columns }
                        selected={ selected }
                      />

                      { data.page.length !== 0 ? (
                        <Pager
                          data={ data }
                          updateParams={ setQuery }
                        />
                      ) : null }
                    </div>
                  ) }
                </Fetch>
              </List>
            </div>

            { isFiltersOpened ? <Filters
              close={ toggleFilters }
            /> : null }
          </> ) }
        </ColumnsContext.Consumer>
      </Filter>
    </Columns>
  )
}
