import React, { useState, useMemo, useEffect } from 'react'
import { LIST_KEY_NAME } from 'constants'
import { withProfile } from 'context/Profile'
import http from 'http'
import {
  FiSearch
} from 'react-icons/fi'

const TitleIsbn = ( {
  model,
  handleChange,
  withProfile: { profile }
} ) => {
  const count = 0
  const [ selectedList, setSelectedList ] = useState( [] )
  const [ isbnList, setIsbnList ] = useState( null )
  const [ isReady, setIsReady ] = useState( false )

  useEffect( () => {
    if ( ! model.title || model.title === '' )
      return setIsReady( true )

    const list = JSON.parse( localStorage.getItem( LIST_KEY_NAME ) || '[]' )
    setSelectedList( list )
    setIsReady( true )

    if ( list.length === 0 ) return

    find()

  }, [ count ] )

  useMemo( () => {
    if ( ! isReady ) return

    localStorage.setItem( LIST_KEY_NAME, JSON.stringify( selectedList ) )
  }, [ selectedList ] )

  const find = () => {
    const withOwner = getOwner()

    http( {
      url: '/api/catalog/sa/find',
      method: 'POST',
      params: {
        __raw: true,
        __limit: 20
      },
      data: {
        ...withOwner,
        titulo: {
          $regex: model.title.toLowerCase(),
          $options: 'i'
        }
      }
    } )
      .then( ( { data } ) => {
        setIsbnList( data )
      } )
  }

  const getOwner = () => {
    const username = Number( profile.username )
    if ( ! Number.isNaN( username ) && username !== 0 ) {
      return { idTitular: username }
    }

    return {}
  }

  const select = ( e ) => {
    const { id, value, checked } = e.target
    const index = selectedList.findIndex( ( a ) => a === value )

    // add
    if ( checked ) {
      if ( index >= 0 )
        return

      return setSelectedList( [ ...selectedList, value ] )
    }

    // remove
    if ( index === -1 )
      return

    setSelectedList( [ ...selectedList ].filter( a => a !== value ) )
  }

  const hc = ( e ) => {
    handleChange( e )

    setSelectedList( [] )
    setIsbnList( [] )
  }

  return <>
    <div className="vs padding gap">
      <div className="one hs">
        <label>T&iacute;tulo</label>

        <div className="vs">
          <input
            type="text"
            name="title"
            id="4fR33Fos5xK9usZpmug9gBYvrIKe4tUy"
            placeholder="t&iacute;tulo"
            value={ model.title }
            onChange={ hc }
            className="one"
          />

          <button
            className="square"
            type="button"
            onClick={ find }
          >
            <FiSearch />
          </button>
        </div>
      </div>
    </div>

    { isbnList ? <div className="hs padding gap isbn-list">
      { isbnList.map( ( item, index ) => {
        const checked = selectedList.find( i => i === item.identificador ) && true || false

        return <div
          key={ index }
          className="one vs centered-items"
        >
          <input
            id={ item._id }
            value={ item.identificador }
            type="checkbox"
            onChange={ select }
            checked={ checked }
          />

          <label htmlFor={ item._id }>
            <strong>{ item.identificador }</strong>
            &nbsp;
            <i>{ item.titulo }</i>
          </label>
        </div>
      } ) }
    </div> : null }
  </>
}

export default withProfile( TitleIsbn )
