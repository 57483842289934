import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import http from '../http'
import logo from '../assets/logo.png'

const ErrorPage = () => {
  const navigate = useNavigate()

  useEffect( () => {
    http
      .get( '/api', { rawError: true } )
      .then( response => {
        navigate( '/board' )
      } )
      .catch( err => {
        if ( err.response.status === 401 )
          navigate( '/auth/login' )
      } )
  } )

  return <main className="vs middle">
    <div className="hs middle">
      <img src={ logo } alt="logo" className="w-400x" />
      <p className="text x4">estamos trabajando...</p>
    </div>
  </main>
}

export default ErrorPage
