import React, { useState } from 'react'
import { withFilter } from '../context/Filter'
import { withProfile } from '../context/Profile'
import moment from 'moment'
import http from '../http'

import {
  FiLoader
} from 'react-icons/fi'

function getExt( mime ) {
  switch ( mime ) {
    case 'text/csv':
      return '.csv'
    case 'application/zip':
      return '.zip'
    default:
      return ''
  }
}

function DownloadButton( props ) {
  const {
    title,
    loadingTitle = 'construyendo archivo',
    endpoint,
    useFilter = 0,
    prefix,
    action
  } = props
  const [ isLoading, setIsLoading ] = useState( false )
  const { profile } = props.withProfile

  // before
  let before = props.before || {}
  if ( before instanceof Array === false )
    before = [ before ]

  function save( response ) {
    setIsLoading( false )
    const mime = response.headers[ 'content-type' ].split( /\s+/ )[0].replace( /;$/, '' )
    const ext = getExt( mime )

    const downloadUrl = window.URL.createObjectURL( new Blob( [ response.data ] ) )
    const filename = `${ prefix }${ moment().format( 'YYYY-MM-DD' ) }${ ext }`
    const link = document.createElement( 'a' )
    link.href = downloadUrl
    link.setAttribute( 'download', filename )
    link.click()
  }

  function download() {
    if ( isLoading )
      return

    setIsLoading( true )

    if ( action )
      return action( save )

    const options = {
      url: endpoint,
      method: 'POST',
      responseType: 'blob',
      silence: true
    }

    let body = []
    if ( useFilter )
      body = props.withFilter.state.steps

    // HARDCODE filter by owner
    const username = Number( profile.username )
    if ( ! Number.isNaN( username ) && username !== 0 ) {
      body = [
        {
          __external: {
            from: 'datos',
            localField: 'obra_interno',
            foreignField: 'IdObraInternoCEDRO',
            match: { IdTitular: username },
            unique: true
          }
        },
        ...body
      ]
    }

    // HARDCODE filter by status
    body = [
      ...before,
      ...body
    ]

    if ( body.length > 0 )
      options[ 'data' ] = body

    http
      .request( options )
      .then( save )
  
  }

  return (
    <button
      title={ isLoading ? loadingTitle : title }
      onClick={ download }
    >
      { isLoading ? (
        <FiLoader className="spin" />
      ) : props.children }
    </button>
  )
}

export default withFilter( withProfile( DownloadButton ) )
