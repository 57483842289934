import React, { useState, useRef } from 'react'
import { withCatalog } from '../../../context/Catalog'
import { withProfile } from '../../../context/Profile'
import { withFilter } from '../../../context/Filter'
import Autocomplete from '../../../components/Autocomplete'
import Fetch from '../../../components/Fetch'
import Select from '../../../components/Select'
import TitleIsbn from './_TitleIsbn'
import serialize from '../../../helpers/serialize'
import flatten from 'flat'
import set from 'set-value'

import {
  FiCheck,
  FiX,
} from 'react-icons/fi'

import { AiOutlineClear } from 'react-icons/ai'

function Filters( props ) {
  const { close } = props
  const { filters: collection } = props.withProfile.profile
  const { filters: catalog } = props.withCatalog.catalog
  const [ model, setModel ] = useState( { ...props.withFilter.state.model } )
  const [ hydrated, setHydrated ] = useState( { ...props.withFilter.state.hydrated } )
  const formElem = useRef( null )

  function submit( event ) {
    const flatFilters = catalog
      .reduce( ( acc, cur ) => { return [
        ...acc,
        ...cur.row.reduce( ( a, c ) => { return [ ...a, c ] }, [] )
      ] }, [] )

    const parsedVariables = serialize( hydrated )
    const steps = Object
      .keys( parsedVariables )
      .map( k => {
        const f = flatFilters.find( ff => ff.identity === k )
        if ( ! f ) return null

        return JSON.parse(
          JSON
            .stringify( f.params.__external )
            .replaceAll(
              '"VALUE"',
              JSON.stringify( parsedVariables[ k ] )
            )
        )
      } )
      .reduce( ( acc, cur ) => {
        if ( acc.length === 0 )
          return [ cur ]

        let group = acc.find( a => a.from === cur.from && a.localField === cur.localField && a.foreignField === cur.foreignField )

        if ( ! group )
          return [ ...acc, cur ]

        const flatMatch = flatten( cur.match )
        Object
          .keys( flatMatch )
          .forEach( m => set( group.match, m, flatMatch[ m ] ) )

        return acc
      }, [] )
      .map( item => ( { __external: item } ) )

    props.withFilter.update( {
      model,
      hydrated,
      steps
    } )
    close()
  }

  function clear( event ) {
    setModel( {} )
    setHydrated( {} )
    props.withFilter.reset()
    formElem.current.reset()
    close()
  }

  function handleChange( event ) {
    const { name, type, value, id } = event.target
    const before = model[ name ]
    let aux

    switch( type ) {
      case 'checkbox':
        aux = new Set()
        if ( before instanceof Set )
          aux = before

        if ( aux.has( value ) ) {
          aux.delete( value )
          break
        }

        aux.add( value )

        break

      case 'number':
        aux = Number( value )
        break

      default:
        aux = value
    }

    setHydrated( { ...hydrated, [ id ]: aux } )
    setModel( { ...model, [ name ]: aux } )
  }

  function buildFormRow( row ) {
    return Object.keys( row )
      .map( key => ( {
        name: key,
        ...row[ key ]
      } ) )
      .sort( ( a, b ) => a.order - b.order )
      .map( field => {
        return (
          <div
            key={ field.name }
            className="form-group"
          >
            <label>{ field.label }</label>

            { field.autocomplete ? (
              <Autocomplete
                id={ field.identity }
                name={ field.name }
                placeholder={ field.placeholder || field.label }
                value={ model[ field.name ] }
                onSelect={ handleChange }
                { ...field.autocomplete }
              />
            ) : [ 'checkbox', 'radio' ].indexOf( field.type ) === -1 ? (
              <input
                id={ field.identity }
                type={ field.type }
                name={ field.name }
                size="1"
                placeholder={ field.placeholder || field.label }
                value={ model[ field.name ] }
                onChange={ handleChange }
              />
            ) : field.__select ? <Fetch
              endpoint={ field.__select.endpoint }
              params={ field.__select.params }
            >
              { ( data ) => (
                <Select
                  id={ field.identity }
                  name={ field.name }
                  value={ model[ field.name ] }
                  multiple={ field.type === 'checkbox' }
                  options={ data || [] }
                  onChange={ handleChange }
                  placeholder={ field.placeholder || field.label }
                />
              ) }
            </Fetch> : (
              <Select
                id={ field.identity }
                name={ field.name }
                value={ model[ field.name ] }
                multiple={ field.type === 'checkbox' }
                options={ field.enum || [] }
                onChange={ handleChange }
                placeholder={ field.placeholder || field.label }
              />
            ) }
          </div>
        )
      } )
  }

  function buildForm( template ) {
    const isComplex = template instanceof Array
    const classes = [ 'one', 'one-two', 'one-third' ]

    if ( isComplex ) {
      return template
        .map( row => buildFormRow( row ) )
        .map( ( row, index ) => {
          const className = classes[ row.length - 1 ]
          return <div
            key={ index }
            className={ 'row ' + className }
          >
            { row }
          </div>
        } )
    }

    return buildFormRow( template )
  }

  // prevent render
  if ( collection.length === 0 )
    return <div className="modal x50">
      loading
    </div>

  const form = buildForm( (
    catalog
      .sort( ( a, b ) => a.order - b.order )
      .map( el => el.row )
      .map( row => {
        return row
          .filter( col => {
            return collection.find( a => a === col.identity ) ? true : false
          } )
      } )
      .filter( row => row.length !== 0 )
  ) )

  return (
    <div className="modal x50">
      <main>
        <header>
          <h1>Filtros</h1>

          <div className="actions">
            <button title="aplicar filtros" onClick={ submit }>
              <FiCheck />
            </button>

            <button title="eliminar todos los filtros" onClick={ clear }>
              <AiOutlineClear />
            </button>

            <button title="cancelar" onClick={ close }>
              <FiX />
            </button>
          </div>
        </header>

        <section className="filter">
          <form ref={ formElem }>

            <TitleIsbn
              model={ model }
              handleChange={ handleChange }
            />

            { form }
          </form>
        </section>
      </main>
    </div>
  )
}

export default withCatalog( withProfile( withFilter( Filters ) ) )
