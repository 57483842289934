import Fetch from '../../../components/Fetch'
import buildIcon from '../../../helpers/buildIcon'
import arrayize from './utils/arrayize'

export default function Number( props ) {
  const { card, before: globalBefore } = props

  // prepare BEFORE
  let before = arrayize( card.before )
  if ( globalBefore )
    before = [ ...arrayize( globalBefore ), ...before ]

  const Icon = buildIcon( card.icon )

  return (
    <div className="hs one card padding" >
      <div className="vs one">
        <div className="zero">
          <Icon
            className="text x5"
            style={ {
              color: card.color
            } }
          />
        </div>

        <div className="hs one">
          <h1 className="text p121 uppercase">
            { card.title }
          </h1>

          <span className="text p149 bold">
            <Fetch
              type="number"
              endpoint={ card.endpoint }
              method={ card.method }
              params={ card.params }
              useFilter={ card.useFilter }
              before={ before }
            />
          </span>
        </div>
      </div>

      { card.items && card.items.length !== 0 ? (
        <div className="vs">
          { card.items.map( ( el, i ) => (
            <div
              key={ i }
              className="hs one centered"
            >
              <h3 className="text p77 uppercase">
                { el.title }
              </h3>

              <span className="text p107 bold">
                <Fetch
                  type="number"
                  endpoint={ el.endpoint }
                  params={ el.params }
                  useFilter={ el.useFilter }
                  before={ before }
                />
              </span>
            </div>
          ) ) }
        </div>
      ) : null }

    </div>
  )
}
