// import React from 'react'
import { withProfile } from '../../../context/Profile'
import { withCatalog } from '../../../context/Catalog'
import Columns from '../../../context/Columns'
import buildIcon from '../../../helpers/buildIcon'
import buildCard from './utils/buildCard'

// action components
import ExportTable from '../../../components/Table/_ExportTable'
import ColumnsSelector from '../../../components/Table/_ColumnsSelector'

function Fragment( props ) {
  return <div {...props}>
  </div>
}

const actionComponents = {
  ExportTable,
  ColumnsSelector
}

function Cards( props ) {
  const { cards: collection } = props.withProfile.profile
  const { cards: catalog } = props.withCatalog.catalog
  const { cards: localCards } = props.withCatalog
  const { before = [] } = props

  const sections = catalog
    .sort( ( a, b ) => a.order - b.order )
    .filter( section => collection.find( el => el.startsWith( section.identity ) ) )
    .filter( section => localCards.find( el => el.identity === section.identity ) )
    .map( section => {
      if ( ! section.childs )
        return section

      const items = section.childs
        .filter( item => collection.find( el => el.endsWith( item.identity ) ) )

      return { ...section, items }
    } )
    .map( ( section, i ) => {
      const Icon = buildIcon( section.icon )

      let Wrapper = Fragment

      let wrapProps = {}
      if ( section.type === 'table' ) {
        Wrapper = Columns
        wrapProps = { ...section.columns }
      }

      const sectionHeader = <div className="title">
        <Icon />

        <h1>
          { section.title }
        </h1>

        { section.actions && section.actions.length !== 0 ? (
          <div className="actions">
            { section.actions.map( ( action, i ) => {
              const Action = actionComponents[ action.component ]

              return <Action key={ i } action={ action } />
            } ) }
          </div>
        ) : null }
      </div>

      const card = buildCard( section, i, before )

      let excludedColumns = [ 'Engine', 'percent_found', 'percent_searched' ]
      const username = Number( props.withProfile.profile.username )
      if ( Number.isNaN( username ) || username === 0 ) {
        excludedColumns = []
      }

      wrapProps = {
        ...wrapProps,
        exclude: excludedColumns
      }

      return <Wrapper
        key={ i }
        { ...wrapProps }
      >
        { sectionHeader }

        { card }
      </Wrapper>
    } )

  return (
    <>
      { sections }
    </>
  )
}

export default withCatalog( withProfile( Cards ) )
