import React, { useState, useEffect } from 'react'
import http from '../../http'
import { withList } from '../../context/List'
import Empty from '../Empty'
import buildIcon from '../../helpers/buildIcon'
import EditableField from '../EditableField'
import ButtonToModal from '../ButtonToModal'
import transformations from '../../transformations'
import get from 'get-value'
import set from 'set-value'
import flat from 'flat'

function T( props ) {
  const {
    data,
    columns,
    actions = [],
    refresh = () => {}
  } = props

  const {
    list,
    add,
    remove,
    clear,
    isToggled
  } = props.withList

  useEffect( () => {
    const toSet = [ ...list ]
    const toUnset = data
      .map( row => row._id )
      .filter( identity => toSet.indexOf( identity ) === -1 )

    toSet
      .forEach( identity => {
        const control = document.getElementById( identity )
        if ( ! control ) return
        control.checked = true
      } )

    toUnset
      .forEach( identity => {
        const control = document.getElementById( identity )
        if ( ! control ) return
        control.checked = false
      } )
  }, [ list ] )

  function toggleSelectedItem( e, row ) {
    const { checked } = e.target
    const { _id: identity } = row

    if ( checked )
      add( identity )
    else
      remove( identity )
  }

  function toggleAll( e ) {
    const { checked } = e.target

    if ( checked ) {
      data
        .map( row => row._id )
        .forEach( identity => add( identity ) )
    } else {
      clear()
    }
  }

  let headers
  // headers
  headers = [
    ...(
      columns
        .filter( col => col.view )
        .map( col => (
          <td
            key={ col.name }
            className={ col.span }
          >
            <span>{ col.as || col.name }</span>
          </td>
        ) )
    )
  ]

  if ( actions.length !== 0 ) {
    headers = [
      ...headers,
      <td
        key="actions"
        className={ 'actions x' + actions.length }
      >
      </td>
    ]
  }

  if ( isToggled ) {
    headers = [
      <td
        key="select"
        className="actions select-ctrl"
      >
        <label>
          <input type="checkbox" onChange={ toggleAll } />
        </label>
      </td>,
      ...headers
    ]
  }

  // rows
  let rows = data
    .map( row => {
      let cols = []

      cols = [
        ...cols,
        ...(
          columns
            .filter( col => col.view )
            .map( col => {
              let content = get( row, col.name )

              if ( col.transform ) {
                const { params = [] } = col
                const fn = transformations[ col.transform ]
                content = fn( content, ...params )
              }

              if ( col.__input ) {
                content = <EditableField
                  identity={ row._id }
                  initialValue={ content }
                  refresh={ refresh }
                  { ...col.__input }
                />
              }

              return(
                <td
                  key={ col.name }
                  className={ col.span }
                >
                  <span>{ content }</span>
                </td>
              )
            } )
        )
      ]

      if ( actions.length !== 0 ) {
        cols = [
          ...cols,
          <td
            key="actions"
            className="actions"
          >
            {
              actions
                .map( a => {
                  const {
                    icon,
                    title,
                    endpoint,
                    method = 'POST',
                    project = [],
                    params = {},
                    modal
                  } = a

                  const Icon = buildIcon( icon )

                  // without action
                  let action = function( e ) {
                    console.log( { a, row } )
                  }

                  // with endpoint
                  if ( endpoint ) {
                    action = function( e ) {
                      const flattenParams = flat( params )
                      let data = {}

                      // use project
                      project
                        .forEach( k => {
                          set( data, k, row[ k ] )
                        } )

                      // parse params
                      Object.keys( flattenParams )
                        .forEach( key => {
                          const hasVariable = flattenParams[ key ]
                            .toString()
                            .startsWith( '$$' )

                          if ( hasVariable ) {
                            const keyName = flattenParams[ key ]
                              .replace( /^\$\$/, '' )

                            return set( data, key, row[ keyName ] )
                          }

                          set( data, key, flattenParams[ key ] )
                        } )

                      http( {
                        url: a.endpoint,
                        method,
                        data
                      } )
                        .then( response => {
                          refresh()
                        } )
                    }
                  }

                  if ( modal ) {
                    return <ButtonToModal
                      key={ title }
                      button={ { ...a, row, refresh } }
                    / >
                  }

                  return <button
                    key={ title }
                    title={ title }
                    onClick={ action }
                  >
                    <Icon />
                  </button>
                } )
            }
          </td>
        ]
      }

      if ( isToggled ) {
        cols = [
          <td
            key="select"
            className="actions select-ctrl"
          >
            <label>
              <input
                id={ row._id }
                type="checkbox"
                onChange={ ( e ) => toggleSelectedItem( e, row ) }
              />
            </label>
          </td>,
          ...cols
        ]
      }

      return (
        <tr key={ row._id }>
          { cols }
        </tr>
      )
    } )

  return (
    <>
      { data.length !== 0 ? (
        <div className="scrollable-x">
          <table>
            <thead>
              <tr>
                { headers }
              </tr>
            </thead>

            <tbody>
              { rows }
            </tbody>
          </table>
        </div>
      ) : <Empty /> }
    </>
  )
}

export default withList( T )
