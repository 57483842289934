import Group from '../_Group'
import Number from '../_Number'
import Pie from '../_Pie'
import Area from '../_Area'
import Table from '../_Table'

export default function buildCard( card, index, before = [] ) {

  switch ( card.type ) {
    case 'group':
      return <Group
        key={ index }
        items={ card.items }
        before={ before }
      />

    case 'number':
      return <Number
        key={ index }
        card={ card }
        before={ before }
      />

    case 'pie':
      return <Pie
        key={ index }
        card={ card }
        before={ before }
      />

    case 'area':
      return <Area
        key={ index }
        card={ card }
        before={ before }
      />

    case 'table':
      return <Table
        key={ index }
        card={ card }
        before={ before }
      />

    default:
      return 'unsupported card'
  }
}
