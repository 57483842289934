import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Skin from './context/Skin'
import Alert from './context/Alert'
import AlertList from './components/AlertList'
import Blank from './components/Blank'
import App from './App'
import Board from './routes/Board'
import { ApiCollections } from './routes/API'
import { Find, Edit } from './routes/Permissions'
import Redirect from './components/Redirect'
import { Tokenize, Login } from './routes/auth'
import ErrorPage from './routes/ErrorPage'
import reportWebVitals from './reportWebVitals'
import './style/style.sass'

const rootElement = document.getElementById( 'root' )

const Sk = ( props ) => {

  return <Skin endpoint="/api/collection/skin/first">
    <Alert>
      <AlertList />

      { props.children }

    </Alert>
  </Skin>
}

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={ <Sk><App /></Sk> }>
        <Route path="board" element={ <Board /> } />
        <Route path="permissions" element={ <Find /> } />
        <Route path="permissions/:identity" element={ <Edit /> } />
        <Route path="a-pi" element={ <ApiCollections /> } />

        <Route
          index
          element={ <Redirect to="/board" /> }
        />

        <Route
          path="*"
          element={ <Redirect to="/board" /> }
        />
      </Route>

      <Route path="error" element={ <ErrorPage /> } />

      <Route path="auth" element={ <Sk><Blank /></Sk> }>
        <Route path="login" element={ <Login /> } />

        <Route path=":token" element={ <Tokenize /> } />
      </Route>
    </Routes>
  </BrowserRouter>,
  rootElement
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
