import http from '../../http'
import { withColumns } from '../../context/Columns'
import { withFilter } from '../../context/Filter'
import { withProfile } from '../../context/Profile'
import DownloadButton from '../../components/DownloadButton'
import buildIcon from '../../helpers/buildIcon'

function ExportTable( props ) {
  const { action, withColumns } = props
  const { endpoint = '/api/report/export' } = action
  const { profile } = props.withProfile

  const Icon = buildIcon( action.icon )

  const {
    model = {},
    steps = []
  } = props.withFilter.state

  // before
  let before = action.before || {}
  if ( before instanceof Array === false )
    before = [ before ]

  function downloadTable( cb ) {
    const cols = withColumns.columns
      .filter( ( col ) => col.view )

    let body = [
      ...steps,
      { __columns: cols }
    ]

    // HARDCODE filter by owner
    const username = Number( profile.username )
    if ( ! Number.isNaN( username ) && username !== 0 ) {
      body = [
        {
          __external: {
            from: 'datos',
            localField: 'obra_interno',
            foreignField: 'IdObraInternoCEDRO',
            match: { IdTitular: username },
            unique: true
          }
        },
        ...body
      ]
    }

    // HARDCODE filter by status
    body = [
      ...before,
      ...body
    ]

    http
      .request( {
        url: endpoint,
        method: 'POST',
        responseType: 'blob',
        data: body,
        silence: true
      } )
      .then( cb )
  }

  return (
    <>
      <DownloadButton
        title={ action.title }
        uri={ action.endpoint }
        prefix={ action.prefix }
        useFilter={ action.useFilter }
        action={ downloadTable }
      >
        <Icon />
      </DownloadButton>
    </>
  )
}

export default withColumns( withFilter( withProfile( ExportTable ) ) )
