import React, { Fragment } from 'react'
import { withCatalog } from '../../context/Catalog'
import { withProfile } from '../../context/Profile'
import ButtonToModal from '../../components/ButtonToModal'
import DownloadButton from '../../components/DownloadButton'
import buildIcon from '../../helpers/buildIcon'

function ExportButtons( props ) {
  const { exportButtons: collection } = props.withProfile.profile
  const { exportButtons: catalog } = props.withCatalog.catalog

  const buttons = collection
    .map( identity => {
      return catalog.find( el => el.identity === identity )
    } )
    .sort( ( a, b ) => a.order - b.order )
    .map( ( button, i ) => {
      const Icon = buildIcon( button.icon )

      if ( button.modal ) {
        return <ButtonToModal key={ i } button={ button } />
      }

      return <DownloadButton
        key={ i }
        title={ button.title }
        endpoint={ button.endpoint }
        prefix={ button.prefix }
        useFilter={ button.useFilter }
        before={ button.before }
      >
        <Icon />
      </DownloadButton>
    } )

  return (
    <>
      { buttons }
    </>
  )
}

export default withCatalog( withProfile( ExportButtons ) )
