import buildIcon from '../helpers/buildIcon'

const Icon = ( { icon } ) => {

  const I = buildIcon( icon )

  return <I />
}

export default Icon
