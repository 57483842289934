import React, { useState } from 'react'
import Fetch from '../../../components/Fetch'
import T, { Pager } from '../../../components/Table'
import { withColumns } from '../../../context/Columns'
import List from '../../../context/List'
import arrayize from './utils/arrayize'

function Table( props ) {
  const { card, withColumns, before: globalBefore } = props
  const [ query, setQuery ] = useState( { ...card.query } )
  const selected = useState( [] )

  // prepare BEFORE
  let before = arrayize( card.before )
  if ( globalBefore )
    before = [ ...arrayize( globalBefore ), ...before ]

  return (
    <div className="hs one">
      <List>
        <Fetch
          type="table"
          endpoint={ card.endpoint }
          params={ card.params }
          query={ query }
          useFilter={ card.useFilter }
          before={ before }
        >
          { ( data, update ) => (
            <div className="hs card table">
              <T
                data={ data.page }
                columns={ withColumns.columns }
                selected={ selected }
              />

              <Pager
                data={ data }
                updateParams={ setQuery }
              />
            </div>
          ) }
        </Fetch>
      </List>
    </div>
  )
}

export default withColumns( Table )
