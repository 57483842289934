import Fetch from '../../../components/Fetch'
import Line from '../../../components/Line'
import arrayize from './utils/arrayize'

export default function Area( props ) {
  const { card, before: globalBefore } = props

  // prepare BEFORE
  let before = arrayize( card.before )
  if ( globalBefore )
    before = [ ...arrayize( globalBefore ), ...before ]

  return (
    <div className="vs card graph">
      <Fetch
        type="area"
        endpoint={ card.endpoint }
        params={ card.params }
        useFilter={ card.useFilter }
        before={ before }
      >
        { ( data ) => {
          return <Line
            data={ data.timeline }
            lines={ data.lines }
          />
        } }
      </Fetch>
    </div>
  )
}
