import { withCatalog } from '../context/Catalog'
import Icon from './Icon'

const ToggleCardList = ( props ) => {

  const isActive = ( group ) => {
    const { cards = [] } = props.withCatalog
    const item = cards.find( el => el.identity === group.identity )

    return item ? true : false
  }

  const toggle = ( group ) => {
    let { cards = [] } = props.withCatalog

    if ( ! isActive( group ) ) {
      props.withCatalog.updateCards( [ ...cards, group ] )
      return
    }

    cards = cards.filter( el => el.identity !== group.identity )
    props.withCatalog.updateCards( cards )
  }

  return props.withCatalog.catalog.cards.map( ( group, index ) => <button
    key={ index }
    title={ group.title }
    className={ isActive( group ) ? 'highlight' : '' }
    onClick={ () => toggle( group ) }
  >
    <Icon { ...group } />
  </button> )
}

export default withCatalog( ToggleCardList )
