import buildCard from './utils/buildCard'

export default function Group( props ) {
  const { items, before = [] } = props

  if ( ! items || ( items && items.length === 0 ) )
    return null

  return (
    <div className="vs wrap">
      { items.map( ( card, index ) => buildCard( card, index, before ) ) }
    </div>
  )
}
